import { lazy } from "react";
import Loadable from "app/components/Loadable";
import MissionConfirmation from "./Confirm";
import FuelConsumption from "./Fuel";

const confirm = Loadable(lazy(() => import("./Confirm")));

const noheadRoutes = [
    { path: "/mission/confirm/:missionId/:pilotId", element: <MissionConfirmation /> },
    { path: "/fuel/:pilotId", element: <FuelConsumption /> },
];

export default noheadRoutes;