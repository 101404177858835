import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { useConfig } from 'app/contexts/ConfigContext';

const FuelConsumption = () => {
    const { pilotId } = useParams();
    const [message, setMessage] = useState('');
    const [maxGallons, setMaxGallons] = useState(0); // This will store the number of gallons from the API
    const [pilotName, setPilotName] = useState(''); // Store the pilot's name from the API
    const [selectedGallons, setSelectedGallons] = useState('');
    const { apiUrl } = useConfig();

    // Fetch the maximum number of gallons and the pilot's name from the API
    useEffect(() => {
        const fetchFuelData = async () => {
            try {
                const response = await axios.get(apiUrl + 'reward/available?pilot_id=' + pilotId);
                const { fuelCredits, name } = response.data; // Assuming response contains `fuel` and `name`
                setMaxGallons(fuelCredits); // Set the max gallons value
                setPilotName(name); // Set the pilot's name
            } catch (error) {
                console.error('Failed to fetch fuel data', error);
            }
        };

        fetchFuelData();
    }, [apiUrl]);

    const fetchFuelData = async () => {
        try {
            const response = await axios.get(apiUrl + 'reward/available?pilot_id=' + pilotId);
            const { fuelCredits, name } = response.data; // Assuming response contains `fuel` and `name`
            setMaxGallons(fuelCredits); // Set the max gallons value
            setPilotName(name); // Set the pilot's name
        } catch (error) {
            console.error('Failed to fetch fuel data', error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl + 'reward/update-fuel', {
                pilotId: parseInt(pilotId, 10),
                selectedGallons, // Send the selected gallons value
            });
            if (response.data === "success") {

                fetchFuelData();
                setMessage('Success! The fuel consumption has been updated.');
            } else {
                setMessage('Something unexpected happened.');
            }
        } catch (error) {
            setMessage('Something unexpected happened.');
        }
    };

    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // 100% of the viewport height
            width: '100vw',  // 100% of the viewport width
            backgroundColor: '#000080', // Set the background color to blue
            margin: 0,      // Remove margin
            padding: 0,     // Remove padding
            boxSizing: 'border-box', // Ensure padding and border are included in the total width and height
            minWidth: '100%',
        }}>

            {/* Logo */}
            <img src={`${apiUrl}images/login-logo.png`} style={{ width: '150px', marginBottom: '20px' }} />

            {/* Title */}
            <Typography variant="h5" gutterBottom style={{ color: 'white' }}>
                {pilotName ? `${pilotName}'s Fuel Credits` : 'Fuel Credits'}
            </Typography>
            <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
                {maxGallons ? `${maxGallons} Gallons` : 'Fuel Credis'}
            </Typography>

            {/* Form */}
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                <input type="hidden" name="pilotId" value={pilotId} />

                {/* Select Field */}
                <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
                    <InputLabel id="gallons-label">Select Fuel Consumption</InputLabel>
                    <Select
                        labelId="gallons-label"
                        id="gallons"
                        value={selectedGallons}
                        onChange={(e) => setSelectedGallons(e.target.value)}
                        label="Select Fuel Consumption"
                        required
                    >
                        {Array.from({ length: Math.floor(maxGallons / 10) }, (_, index) => (
                            <MenuItem key={(index + 1) * 10} value={(index + 1) * 10}>
                                {`${(index + 1) * 10} gallon(s) fuel`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button type="submit" variant="contained" color="success" style={{ marginTop: '20px' }}>
                    Confirm
                </Button>
            </form>

            {/* Message Display */}
            {message && <Typography variant="h6" color="secondary" style={{ marginTop: '20px' }}>{message}</Typography>}
        </Container>
    );
};

export default FuelConsumption;
