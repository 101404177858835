import { lazy } from "react";
import Loadable from "app/components/Loadable";
// import { Update } from "@mui/icons-material";

// const Faq1 = Loadable(lazy(() => import("./faq/Faq1")));
// const Faq2 = Loadable(lazy(() => import("./faq/Faq2")));
// const OrderList = Loadable(lazy(() => import("./orders/OrderList")));
// const UserList1 = Loadable(lazy(() => import("./user-list/UserList1")));
const UserManagement = Loadable(lazy(() => import("./user-list/UserManagement")));
// const UserList3 = Loadable(lazy(() => import("./user-list/UserList3")));
// const UserList4 = Loadable(lazy(() => import("./user-list/UserList4")));
// const ProductList = Loadable(lazy(() => import("./products/ProductList")));
// const ProductForm = Loadable(lazy(() => import("./products/ProductForm")));
// const CustomerList = Loadable(lazy(() => import("./customers/CustomerList")));
// const ProductViewer = Loadable(lazy(() => import("./products/ProductViewer")));
// const CustomerForm = Loadable(lazy(() => import("./customers/customer-form/CustomerForm")));
// const CustomerViewer = Loadable(lazy(() => import("./customers/customer-viewer/CustomerViewer")));

const PatientForm = Loadable(lazy(() => import("./patients/patient-form/PatientForm")));
const PatientList = Loadable(lazy(() => import("./patients/patient-form/PatientList")));
const PatientDetails = Loadable(lazy(() => import("./patients/patient-form/PatientDetails")));
const PatientProfile = Loadable(lazy(() => import("./patients/patient-form/PatientProfile")));
const ProfileOfPatient = Loadable(lazy(() => import("./patients/patient-form/ProfileForParient")));
const PilotForm = Loadable(lazy(() => import("./pilots/pilot-form/PilotForm")));
const PilotList = Loadable(lazy(() => import("./pilots/pilot-form/PilotList")));
const PilotDetails = Loadable(lazy(() => import("./pilots/pilot-form/PilotDetails")));
const PilotProfile = Loadable(lazy(() => import("./pilots/pilot-form/PilotProfile")));
const PilotContact = Loadable(lazy(() => import("./pilots/pilot-form/ContactPilot")));
const ProfileOfPilot = Loadable(lazy(() => import("./pilots/ProfileOfPilot")));
const MissionForm = Loadable(lazy(() => import("./missions/MissionForm")));
const MissionList = Loadable(lazy(() => import("./missions/MissionList")));
const MissionArchived = Loadable(lazy(() => import("./missions/MissionArchived")));
const PilotsMissionArchived = Loadable(lazy(() => import("./missions/MissionArchiveForPilot")));
const PatientsMissionArchived = Loadable(lazy(() => import("./missions/MissionArchiveForPatient")));
const MissionDetails = Loadable(lazy(() => import("./missions/MissionDetails")));
const MissionDetailsOnly = Loadable(lazy(() => import("./missions/MissionDetailsOnly")));
const MissionDetailsForPatient = Loadable(lazy(() => import("./missions/MissionDetailsForPatient")));
const AvailableMission = Loadable(lazy(() => import("./missions/AvailableMissions")));
const DestinationForm = Loadable(lazy(() => import("./Destinations/DestinationForm")));
const DestinationDetails = Loadable(lazy(() => import("./Destinations/DestinationDetails")));
const DestinationList = Loadable(lazy(() => import("./Destinations/DestinationList")));
const SmsLogList = Loadable(lazy(() => import("./smslog/sms-log")));
const Calendar = Loadable(lazy(() => import("../calendar/MatxCalendar")));
const UpdatePassword = Loadable(lazy(() => import("./user-control/UpdatePassword")));
const AddNewUser = Loadable(lazy(() => import("./user-control/AddNewUser")));
const PilotGroupForm = Loadable(lazy(() => import("./PilotGroups/PilotGroupForm")));
const PilotGroupList = Loadable(lazy(() => import("./PilotGroups/PilotGroupList")));
const PilotGroupDetails = Loadable(lazy(() => import("./PilotGroups/PilotGroupDetails")));
const PilotGroupAssignments = Loadable(lazy(() => import("./PilotGroups/PilotGroupAssignments")));
const AdminSettings = Loadable(lazy(() => import("./admin/AdminSettings")));
const TestUpload = Loadable(lazy(() => import("./admin/testImageUpload")));
const ImageUpload = Loadable(lazy(() => import("./UploadResouces/UploadForm")));
const UploadList = Loadable(lazy(() => import("./UploadResouces/UploadList")));
const PilotsMisssionHistory = Loadable(lazy(() => import("./Mission_Reports/PilotsMisssionHistory")));
const PilotsMissionsWithinDate = Loadable(lazy(() => import("./Mission_Reports/PilotsMissionsWithinDate")));
const PilotFuelHistory = Loadable(lazy(() => import("./Mission_Reports/PilotFuelHistory")));
const RewardHistory = Loadable(lazy(() => import("./reward/reward-history")));

const pagesRoutes = [
  { path: "/pages/new-patient", element: <PatientForm /> },
  { path: "/pages/patient-profile/:id", element: <PatientProfile /> },
  { path: "/patient/profile", element: <ProfileOfPatient /> },
  { path: "/pages/patient-list", element: <PatientList /> },
  { path: "/pages/patient-details/:id", element: <PatientDetails /> },
  { path: "/pages/new-pilot", element: <PilotForm /> },
  { path: "/pages/pilot-list", element: <PilotList /> },
  { path: "/pages/pilot-details/:id", element: <PilotDetails /> },
  { path: "/pages/pilot-profile/:id", element: <PilotProfile /> },
  { path: "/pages/new-mission", element: <MissionForm /> },
  { path: "/pages/mission-list", element: <MissionList /> },
  { path: "/pilot/available-mission", element: <AvailableMission /> },
  { path: "/pages/mission-list-old", element: <MissionArchived /> },
  { path: "/pilot/old-mission-list", element: <PilotsMissionArchived /> },
  { path: "/pages/mission-details/:id", element: <MissionDetails /> },
  { path: "/pilot/mission-info/:id", element: <MissionDetailsOnly /> },
  { path: "/patient/mission-info/:id", element: <MissionDetailsForPatient /> },
  { path: "/pages/destination-details/:id", element: <DestinationDetails /> },
  { path: "/pages/new-destination/", element: <DestinationForm /> },
  { path: "/pages/destination-list/", element: <DestinationList /> },
  { path: "/pages/smslog/", element: <SmsLogList /> },
  { path: "/calendar/", element: <Calendar /> },
  { path: "/users/update-password", element: <UpdatePassword /> },
  { path: "/users/add-new", element: <AddNewUser /> },
  { path: "/pilot/profile", element: <ProfileOfPilot /> },
  { path: "/pages/new-group", element: <PilotGroupForm /> },
  { path: "/pages/group-list", element: <PilotGroupList /> },
  { path: "/pages/group-details/:id", element: <PilotGroupDetails /> },
  { path: "/pages/group-assign/:id", element: <PilotGroupAssignments /> },
  { path: "/pages/settings-details", element: <AdminSettings /> },
  { path: "/pages/user-management", element: <UserManagement /> },
  { path: "/pages/settings-test", element: <TestUpload /> },
  { path: "/pages/upload-image", element: <ImageUpload /> },
  { path: "/pages/upload-list", element: <UploadList /> },
  { path: "/patient/old-mission-list", element: <PatientsMissionArchived /> },
  { path: "/reports/pilot-mission-list", element: <PilotsMisssionHistory /> },
  { path: "/reports/pilot-missions-list", element: <PilotsMissionsWithinDate /> },
  { path: "/pages/contact-pilot", element: <PilotContact /> },
  { path: "/pages/reward-history", element: <RewardHistory /> },
  { path: "/pages/fuel-history", element: <PilotFuelHistory /> },
];

export default pagesRoutes;
